import React, { Suspense } from "react";
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import Fallback from '../src/components/Fallback'
import Notfoundpage from './pages/Notfoundpage';
import ScrollToTop from "./components/scrollToTop";


const Home = React.lazy(() => import("./pages/LandingPage"));
const PaymentProcessing = React.lazy(() => import("./pages/PaymentProcessing"));
const DigitalBanking = React.lazy(() => import("./pages/DigitalBanking"));
const BankConnect = React.lazy(() => import("./pages/BankConnect"));
const AcquirerProcessing = React.lazy(() => import("./pages/AcquirerProcessing"));
const PaymentGateway = React.lazy(() => import("./pages/PaymentGateway"));
const POS = React.lazy(() => import("./pages/POS"));
const IssuerProcessing = React.lazy(() => import("./pages/IssuerProcessing"));
const RiskManagement = React.lazy(() => import("./pages/RiskManagement"));
const PaymentSolution = React.lazy(() => import("./pages/PaymentSolution"));
const IntlDonorOverview = React.lazy(() => import("./pages/IntlDonorOverview"));
const OneAfricaCard = React.lazy(() => import("./pages/OneAfricaCard"));
const OneAfricaWallet = React.lazy(() => import("./pages/OneAfricaWallet"));
const OneAfricaSuperapp = React.lazy(() => import("./pages/OneAfricaSuperapp"));

const LandingPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <Home />
    </Suspense>
  </ErrorBoundary>
);

const PaymentProcessingPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <PaymentProcessing />
    </Suspense>
  </ErrorBoundary>
);

const DigitalBankingPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <DigitalBanking />
    </Suspense>
  </ErrorBoundary>
);

const BankConnectPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <BankConnect />
    </Suspense>
  </ErrorBoundary>
);

const AcquirerProcessingPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <AcquirerProcessing />
    </Suspense>
  </ErrorBoundary>
);

const PaymentGatewayPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <PaymentGateway />
    </Suspense>
  </ErrorBoundary>
);

const POSPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <POS />
    </Suspense>
  </ErrorBoundary>
);

const IssuerProcessingPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <IssuerProcessing />
    </Suspense>
  </ErrorBoundary>
);

const RiskManagementPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <RiskManagement />
    </Suspense>
  </ErrorBoundary>
);

const PaymentSolutionPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <PaymentSolution />
    </Suspense>
  </ErrorBoundary>
);

const IntlDonorOverviewPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <IntlDonorOverview />
    </Suspense>
  </ErrorBoundary>
);

const OneAfricaCardPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <OneAfricaCard />
    </Suspense>
  </ErrorBoundary>
);

const OneAfricaWalletPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <OneAfricaWallet />
    </Suspense>
  </ErrorBoundary>
);

const OneAfricaSuperappPage = () => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>
      <OneAfricaSuperapp />
    </Suspense>
  </ErrorBoundary>
);




function App() {
 
  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='*' element={<Notfoundpage />} />
        <Route path='/payment-processing' element={<PaymentProcessingPage />} />
        <Route path='/digital-banking' element={<DigitalBankingPage />} />
        <Route path='/bank-connect' element={<BankConnectPage />} />
        <Route path="/acquirer-processing" element={<AcquirerProcessingPage />} />
        <Route path="/payment-gateway" element={<PaymentGatewayPage />} />
        <Route path="/pos" element={<POSPage />} />
        <Route path="/fraud-risk-monitoring" element={<RiskManagementPage />} />
        <Route path="/issuer-processing" element={<IssuerProcessingPage />} />
        <Route path="/payments-solution" element={<PaymentSolutionPage />} />
        <Route path="/intl-donor-overview" element={<IntlDonorOverviewPage />} />
        <Route path="/1africa-card" element={<OneAfricaCardPage />} />
        <Route path="/1africa-wallet" element={<OneAfricaWalletPage />} />
        <Route path="/1africa-superapp" element={<OneAfricaSuperappPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
