import React from 'react'
import { Link } from 'react-router-dom'

const Notfoundpage = () => {
  return (
    <div className='font-body h-screen flex flex-col gap-8 justify-center items-center'>
        <h1 className='text-8xl'>Error 404</h1>
        <p className='text-4xl'>Page not found!</p>
        <Link className='text-white bg-black p-3 rounded-2xl hover:bg-white hover:text-black hover:border-black border-2 border-white' to='/'>Go Home?</Link>
    </div>
  )
}

export default Notfoundpage